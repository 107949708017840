import React from "react";

import { Row } from "react-bootstrap";
import { ILayers } from "../../../../shared/interfaces/ILayers";
import {
    IProperty,
    IPropertyGeom,
} from "../../../../shared/interfaces/IProperties";
import { Divisor } from "../../bci/styles";

import { TableMap } from "../map/table-map";

interface ZoneamentoProps {
    propertyInfo?: IProperty;
    propertyGeom?: IPropertyGeom;
    layers?: ILayers[];
}

export const Zoneamento: React.FC<ZoneamentoProps> = ({
    propertyInfo,
    propertyGeom,
    layers,
}) => {
    return (
        <>
            <Row>
                <table className="table table-striped">
                    <tbody>
                        {propertyInfo?.inscricaoimobiliaria && (
                            <tr>
                                <td>Inscrição Imobiliária:</td>
                                <td>{propertyInfo?.inscricaoimobiliaria}</td>
                                {propertyGeom && (
                                    <td rowSpan={7}>
                                        <TableMap
                                            propertyGeom={propertyGeom}
                                            layers={layers}
                                        />
                                    </td>
                                )}
                            </tr>
                        )}

                        <tr>
                            <td>Data:</td>
                            <td>
                                {new Date(Date.now()).toLocaleDateString(
                                    "pt-BR"
                                )}
                            </td>
                        </tr>
                        {propertyInfo?.logradouronome && (
                            <tr>
                                <td>Endereço:</td>
                                <td>{propertyInfo?.logradouronome}</td>
                            </tr>
                        )}

                        {propertyInfo?.logradouronumero && (
                            <tr>
                                <td>Número:</td>
                                <td>{propertyInfo?.logradouronumero}</td>
                            </tr>
                        )}

                        {propertyInfo?.cep && (
                            <tr>
                                <td>CEP:</td>
                                <td>{propertyInfo?.cep}</td>
                            </tr>
                        )}

                        {propertyInfo?.quadra && (
                            <tr>
                                <td>Quadra:</td>
                                <td>{propertyInfo?.quadra}</td>
                            </tr>
                        )}

                        {propertyInfo?.lote && (
                            <tr>
                                <td>Lote:</td>
                                <td>{propertyInfo?.lote}</td>
                            </tr>
                        )}

                        <tr>
                            <td>Zoneamento:</td>
                            <td colSpan={2}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Descrição</th>
                                            <th>Principal</th>
                                            <th>Observaçao</th>
                                        </tr>
                                    </thead>

                                    {/* {foreach $consultaSQL.zoneamentos as $valor}
							<tr>
								<td>{$valor.descricao}</td>
								<td>{if $valor.principal==1}Sim{else}Não{/if}</td>
								<td>{$valor.observacao}</td>
							</tr>
							{/foreach} */}
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Row>
            <Divisor />
        </>
    );
};
