import React from "react";

import { Row, Col } from "react-bootstrap";
import { IProperty } from "../../../../shared/interfaces/IProperties";

interface PropertyInfoProps {
    property?: IProperty;
}

export const PropertyInfo: React.FC<PropertyInfoProps> = ({ property }) => {
    return (
        <div className="w-full mt-1 mb-1">
            <Row>
                <Col>PROPRIETÁRIO: {property?.propnome}</Col>
            </Row>

            <Row>
                <Col>CADASTRO IMOBILIÁRIO: {property?.cadastro}</Col>
                <Col>TRANSCRIÇÃO: {property?.matricula}</Col>
            </Row>

            <Row>
                <Col>LOGRADOURO: {property?.logradouronome}</Col>
                <Col>BAIRRO: {property?.bairronome}</Col>
            </Row>

            <Row>
                <Col>QUADRA: {property?.quadra}</Col>
                <Col>LOTE: {property?.lote}</Col>
            </Row>

            <Row>
                <Col>ÁREA: {property?.areaterreno} M²</Col>
            </Row>
        </div>
    );
};
