import JsPDF from "jspdf";
import environments from "../../../../../environments";
import { WebgeoLogo, SemvLogo } from "../../../../../shared/assets/logos";

function drawInPdf(pdf: JsPDF, element: string, index: number) {
    if (element) {
        pdf.setFillColor(element);
    } else pdf.setFillColor("black");

    pdf.rect(1019, index * 5 + 65, 10, 3, "F");
}

export const printA0 = async (
    pdf: JsPDF,
    canvas: HTMLCanvasElement,
    mapTitle: string,
    colors: Array<string>,
    titles: Array<string>
) => {
    pdf.addImage(canvas.toDataURL("image/png", 3), "png", 35, 60, 959, 701);

    pdf.addImage(WebgeoLogo, "png", 1050, 776, 105, 45);
    pdf.addImage(SemvLogo, "png", 45, 776, 110, 45);
    pdf.addImage(WebgeoLogo, "png", 495, 776, 110, 45);
    pdf.setLineWidth(2.5);
    pdf.rect(25, 10, 1154, 821);

    pdf.rect(35, 60, 959, 701);
    pdf.rect(1014, 60, 155, 500);

    pdf.rect(1014, 640, 155, 121);

    pdf.setFillColor(200, 200, 200);
    pdf.rect(35, 20, 959, 30, "F");
    pdf.rect(1014, 20, 155, 30, "F");
    pdf.rect(1014, 600, 155, 30, "F");
    pdf.rect(1014, 600, 155, 30);
    pdf.rect(35, 20, 959, 30);
    pdf.rect(1014, 20, 155, 30);
    pdf.roundedRect(35, 771, 1134, 50, 10, 10);

    pdf.setTextColor(0, 0, 0);

    // pdf.addFont("arial", "arial", "normal");
    pdf.setFont("ARIALBOLD", "bold");

    pdf.setFontSize(48);
    pdf.text(mapTitle.toUpperCase(), 479, 40, {
        align: "center",
    });
    pdf.text("LEGENDA", 1091, 40, { align: "center" });
    pdf.setFontSize(30);
    pdf.text("INFORMAÇÕES DO MAPA", 1091, 575, {
        align: "center",
    });
    pdf.text("MAPA DE LOCALIZAÇÃO", 1091, 618, {
        align: "center",
    });
    pdf.setFontSize(22);
    pdf.text("SIRGAS 2000/UTM zone 22S", 1091, 588, {
        align: "center",
    });
    pdf.setFontSize(10);
    pdf.text(
        `FONTE: SEMV PROJETOS GOVERNAMENTAIS E PREFEITURA DE ${environments.client.name.toUpperCase()} - ANO: 2023`,
        1091,
        595,
        {
            align: "center",
        }
    );
    pdf.setFontSize(24);
    pdf.setFont("ARIAL", "normal");
    pdf.text(
        `MUNICÍPIO DE ${environments.client.name.toUpperCase()}`,
        608,
        795
    );
    pdf.setFontSize(35);
    pdf.setFont("ARIALBOLD", "bold");
    pdf.text(`${environments.client.name.toUpperCase()}`, 608, 810);
    pdf.setFontSize(12);
    pdf.setFont("ARIAL", "normal");

    // await Promise.all(
    //     colors.map(async (element: string, index: number) => {
    //         drawInPdf(pdf, element, index);
    //     })
    // );

    colors.forEach((element: string, index: number) => {
        if (element) {
            pdf.setFillColor(element);
        } else pdf.setFillColor("black");

        pdf.rect(1019, index * 5 + 65, 10, 3, "F");
    });

    titles.forEach((element: string, index: number) => {
        if (element) {
            pdf.text(element, 1032, index * 5 + 68);
        } else pdf.text("Padrão", 1032, index * 5 + 68);
    });

    pdf.save(`map-${new Date(Date.now()).toLocaleDateString()}.pdf`, {
        returnPromise: true,
    }).then(() => {
        // console.log(`${new Date(Date.now()).toLocaleTimeString("pt-BR")}`);
    });

    return pdf;
};
