import JsPDF from "jspdf";
import { WebgeoLogo, SemvLogo } from "../../../../../shared/assets/logos";

const drawLegend = async (
    pdf: JsPDF,
    colors: Array<string>,
    titles: Array<string>
) => {
    colors.forEach((element: string, index: number) => {
        if (index > 10) return;
        if (element && element !== undefined) {
            pdf.setFillColor(element);
            pdf.rect(211, index * 5 + 52, 7, 3, "F");
        } else {
            pdf.setFillColor("black");
            pdf.rect(211, index * 5 + 52, 7, 3, "F");
        }
    });

    titles.forEach((element: string, index: number) => {
        if (index > 10) return;
        if (element) {
            pdf.text(element, 219, index * 5 + 55);
        } else pdf.text("Padrão", 219, index * 5 + 55);
    });
};

export const printA4 = (
    pdf: JsPDF,
    canvas: HTMLCanvasElement,
    mapTitle: string,
    colors: Array<string>,
    titles: Array<string>
) => {
    pdf.setLineWidth(1);
    pdf.rect(7, 25, 283, 178);
    pdf.setFillColor(200, 200, 200);
    pdf.rect(14, 32, 187, 10, "F");
    pdf.rect(208, 32, 75, 10, "F");
    pdf.rect(208, 121, 75, 10, "F");

    pdf.rect(14, 32, 187, 10);
    pdf.rect(208, 32, 75, 10);

    pdf.rect(14, 49, 187, 125);

    pdf.rect(208, 49, 75, 62);

    pdf.rect(208, 121, 75, 10);

    pdf.rect(208, 137, 75, 37);

    pdf.roundedRect(14, 181, 269, 15, 5, 5);

    pdf.setTextColor(0, 0, 0);

    pdf.setFont("ARIALBOLD", "bold");

    pdf.setFontSize(12);
    pdf.text(mapTitle.toUpperCase(), 107.5, 38, {
        align: "center",
    });
    pdf.text("LEGENDA", 245.5, 38, { align: "center" });
    pdf.text("MAPA DE LOCALIZAÇÃO", 245.5, 127, {
        align: "center",
    });
    pdf.setFontSize(30);
    pdf.text("INFORMAÇÕES DO MAPA", 1091, 575, {
        align: "center",
    });

    pdf.setFontSize(10);

    pdf.addImage(canvas.toDataURL("image/jpeg", 1), "JPEG", 14, 49, 187, 125);
    pdf.addImage(WebgeoLogo, "png", 240, 181, 40, 15);
    pdf.addImage(SemvLogo, "png", 14, 181, 40, 15);
    // pdf.addImage(WebgeoLogo, "png", 495, 776, 110, 45);

    drawLegend(pdf, colors, titles).then(() => {
        pdf.save(`map-${new Date(Date.now()).toLocaleDateString()}.pdf`, {
            returnPromise: true,
        }).then(() => {
            // console.log(`${new Date(Date.now()).toLocaleTimeString("pt-BR")}`);
        });
    });

    return pdf;
};
