import React from "react";
import environments from "../../../../environments";

export const Footer: React.FC = () => {
    return (
        <footer className="w-full flex justify-center mt-2 mb-2">
            <small style={{ textAlign: "center", whiteSpace: "pre" }}>
                {environments.webgeo.confrontante?.informacoesRodape}
            </small>
        </footer>
    );
};
