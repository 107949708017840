import React from "react";
import environments from "../../../environments";

import { StandardConsultaPrevia } from "./providers/standard";
import { CastroConsultaPrevia } from "./providers/castro";

export const ConsultaPrevia: React.FC = () => {
    switch (environments.client.name) {
        case "castro":
            return <CastroConsultaPrevia />;
        default:
            return <StandardConsultaPrevia />;
    }
};
