import React, { useCallback, useState } from "react";

import { EventsKey } from "ol/events";
import { MapBrowserEvent } from "ol";
import { unByKey } from "ol/Observable";
import * as proj from "ol/proj";
import { Modal, RoundedButton } from "../../../global";

import { PrintMapIcon } from "../../../../shared/assets/icons";
import { useOlMap } from "../../../../shared/hooks/useOlMap";
import { useToggle } from "../../../../shared/hooks/useToggle";
import { MapToExport } from "../map/export-map";

export const ExportMap: React.FC = () => {
    const { map } = useOlMap();
    const { isActive, toggle } = useToggle();

    const [mapKey, setMapKey] = useState<EventsKey>();
    const [coordinates, setCoordinates] = useState<{
        lat: number;
        lng: number;
    }>();
    const [zoom, setZoom] = useState<number>();

    const coordinatesFromMap = useCallback(() => {
        if (isActive && mapKey) {
            unByKey(mapKey);
        } else {
            const key = map?.on(
                "singleclick",
                (e: MapBrowserEvent<PointerEvent>) => {
                    e.preventDefault();

                    const [lng, lat] = proj.transform(
                        [e.coordinate[0], e.coordinate[1]],
                        "EPSG:3857",
                        "EPSG:4326"
                    );

                    setZoom(map?.getView().getZoom());
                    setCoordinates({
                        lat,
                        lng,
                    });
                }
            );

            setMapKey(key);
        }

        toggle();
    }, [isActive, map, mapKey, toggle]);

    return (
        <>
            <RoundedButton
                active={isActive}
                description="EXPORTAR MAPA"
                onClick={coordinatesFromMap}
            >
                <img
                    src={PrintMapIcon}
                    className="h-7 w-7 md:h-10 md:w-10"
                    alt="EXPORTAR MAPA"
                />
            </RoundedButton>
            {coordinates && zoom && (
                <Modal
                    show={!!coordinates}
                    handleCloseModal={() => setCoordinates(undefined)}
                    size="xl"
                >
                    <MapToExport
                        center={coordinates}
                        willRender={isActive}
                        zoom={zoom}
                    />
                </Modal>
            )}
        </>
    );
};
