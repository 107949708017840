import React, { createContext, useMemo, useState } from "react";

import Map from "ol/Map";

export interface OlPdfContextData {
    map?: Map;
    setMap: React.Dispatch<React.SetStateAction<Map | undefined>>;
}

interface OlPdfProviderProps {
    children: React.ReactNode;
}

const OlPdfContext = createContext<OlPdfContextData>({} as OlPdfContextData);

const OlPdfProvider: React.FC<OlPdfProviderProps> = ({ children }) => {
    const [map, setMap] = useState<Map>();

    const olContext = useMemo(
        () => ({
            map,
            setMap,
        }),
        [map, setMap]
    );

    return (
        <OlPdfContext.Provider value={olContext}>
            {children}
        </OlPdfContext.Provider>
    );
};

export { OlPdfProvider, OlPdfContext };
