import React from "react";

import environments from "../../../../environments";

import { CastroContact } from "./providers/castro";
import { StandardContact } from "./providers/standard";

export const Contact: React.FC = () => {
    switch (environments.client.name) {
        case "castro":
            return <CastroContact />;
        default:
            return <StandardContact />;
    }
};
