export const WebgeoFeaturesLabels: Record<
    string,
    { label: string; order: number; actions: string[] }
> = {
    properties: {
        label: "Propriedade",
        order: 1,
        actions: ["read", "update", "create"],
    },
    properties_history: {
        label: "Histórico de atualizações",
        order: 4,
        actions: ["read"],
    },
    properties_itbi: {
        label: "ITBI",
        order: 4,
        actions: ["read"],
    },
    layers: { label: "Camadas", order: 4, actions: ["read"] },
    bci: {
        label: "Boletim de cadastro imobiliário",
        order: 5,
        actions: ["read"],
    },
    confrontante: { label: "Confrontante", order: 6, actions: ["read"] },
    consulta_previa: { label: "Consulta prévia", order: 7, actions: ["read"] },
    iptu: { label: "IPTU", order: 8, actions: ["read"] },
};

export const WebgeoRessourcesLabels: Record<string, string> = {
    inscricaoimobiliaria: "Inscrição",
    propnome: "Nome proprietário",
    propdocumento: "Documento proprietário",
    respnome: "Nome responsável",
    respdocumento: "Documento responsável",
    cep: "Cep",
    bairronome: "Bairro",
    logradouronome: "Logradouro",
    logradouronumero: "N. Logradouro",
    quadra: "Quadra",
    lote: "Lote",
    areaterreno: "Área terreno",
    profundidade: "Profundidade",
    areaconstruida: "Área construída",
    areatotalconstruida: "Área total construída",
    matricula: "Matrícula",
    zonedescricao: "Descrição do zoneamento",
    zoneobservacao: "Observação do zoneamento",
    zoneprincipal: "Zoneamento principal",
    zonecodigo: "Código do zoneamento",
    cadastro: "Cadastro",
    numerotestada: "Quantidade de testadas",
    ano: "Ano",
    metragem: "Metragem",
    valorvenalterritorial: "Valor venal territorial",
    valorvenalpredial: "Valor venal predial",
    aliquotaterritorial: "Aliquota territorial",
    aliquotapredial: "Aliquota predial",
    valorm2territorial: "Valor M2 terreno",
    valorm2predial: "Valor M2 unidade",
    logradouro: "Logradouro",
    bairro: "Bairro",
    observacao: "Observação",
    anexos: "Anexos",
    tipo: "Tipo",
    situacaocadastral: "Situação cadastral",
    codigosecao: "Código da seção",
    idsecao: "Id da seção",
    alfasecao: "Alfa seção",
    valorsecao: "Valor seção",

    layer_lotes: "Lotes",

    li_id: "Código da alteração",
    li_observacao: "Observação",
    li_anexos: "Anexos",
    inscricao: "Inscrição",
    li_related_by: "Relacionado",
    li_created_at: "Criado em",
    li_update_at: "Atualizado em",
    lote_id: "Código do lote",
    tbimv_id: "Código das informações",
    tb_imv_id: "Código das informações",
    created_by: "Criado por",
};
