export enum FeaturesEnum {
    USERS = "users",
    USERS_MANAGER = "users_manager",

    ROLES = "roles",

    GRP_ACCESS = "grp_access",

    PROJECTS = "projects",

    PERMISSIONS = "permissions",

    PROPERTIES = "properties",
    PROPERTIES_VALUES = "properties_values",
    PROPERTIES_TESTADAS = "properties_testadas",
    CONFRONTANTE = "confrontante",
    BCI = "bci",
    IPTU = "iptu",
    CONSULTA_PREVIA = "consulta_previa",

    LAYERS = "layers",
    LAYERS_MANAGER = "layers_manager",
    LAYERS_GROUPS = "layers_groups",
    LAYERS_STYLES = "layers_styles",

    LOGS = "logs",
}
