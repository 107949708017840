import { FormHandles } from "@unform/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import { Button } from "react-bootstrap";
import JsPDF from "jspdf";
import { getPointResolution } from "ol/proj";
import html2canvas from "html2canvas";
import ScaleLine from "ol/control/ScaleLine";
import Control from "ol/control/Control";
import {
    VisibilityIcon,
    PrintMapIcon,
    InfoIcon,
} from "../../../../shared/assets/icons";
import { Input, Select } from "../../../global";
import { usePdfMap } from "../../../../shared/hooks/usePdfMap";
import { StoragesService } from "../../../../shared/storages/storages.service";
import { storageConstants } from "../../../../shared/utils/storage.utils";
import {
    findLayerByTitle,
    getLayerLegend,
} from "../../../../shared/utils/ol.utils";
import { useAuth } from "../../../../shared/hooks/useAuth";
import "../../../../shared/assets/fonts/ARIAL-normal";
import "../../../../shared/assets/fonts/ARIALBD1-bold";
import { printA0 } from "./pageSize/A0";
import { printA4 } from "./pageSize/A4";
import { mapDims } from "./pageSize/Dims";

// const afterPrint = 0;

export const PrintMapScreen: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { map } = usePdfMap();
    const { user } = useAuth();
    const [zoom, setZoom] = useState<number>();

    const [mycanvas, setMyCanvas] = useState<HTMLCanvasElement>();
    const [titles, setTitles] = useState<Array<string>>([]);
    const [colors, setColors] = useState<Array<string>>([]);
    // const [scaleLineControl, setScaleLineControl] = useState<Control>();

    const createControls = useCallback(async () => {
        if (map) {
            const scaleLine = new ScaleLine({
                units: "metric",
                bar: true,
                text: true,
                minWidth: 125,
            });
            map.addControl(scaleLine);
        }
    }, [map]);

    const createLegend = useCallback(async () => {
        const mtitles: Array<string> = [];
        const mcolors: Array<string> = [];
        const layers = StoragesService.localStorage.getValue(
            storageConstants.ENABLED_LAYERS(user.id)
        );

        if (layers) {
            const t = JSON.parse(layers);
            t.forEach((element: string) => {
                const layerToChange = map
                    ? findLayerByTitle(map, element)
                    : undefined;

                getLayerLegend(layerToChange?.getSource(), "").then((res) => {
                    if (!res.length) return;

                    res.forEach(({ title, fill }) => {
                        mtitles.push(title);
                        mcolors.push(fill as string);
                    });
                });
            });
            setTitles(mtitles);
            setColors(mcolors);
        }
    }, [map, user.id]);

    const printMap = useCallback(async () => {
        document.body.style.cursor = "progress";
        // setTime(new Date(Date.now()).toLocaleTimeString("pt-BR"));
        createLegend().then(() => {
            // map?.once("rendercomplete", async function createPDF() {
            //     const viewResolution = map?.getView().getResolution();
            //     const mapCanvas = document.createElement("canvas");
            //     const size = map?.getSize();
            //     if (size) {
            //         const [width1, height1] = size;
            //         mapCanvas.width = width1;
            //         mapCanvas.height = height1;
            //     }
            //     mapCanvas.setAttribute("crossorigin", "anonymous");
            //     const mapContext = mapCanvas.getContext("2d");
            //     Array.prototype.forEach.call(
            //         document.querySelectorAll(".ol-layer canvas"),
            //         (canvas1) => {
            //             if (canvas1.width > 0) {
            //                 canvas1.setAttribute("crossorigin", "anonymous");
            //                 const { opacity } = canvas1.parentNode.style;
            //                 if (mapContext) {
            //                     mapContext.globalAlpha =
            //                         opacity === "" ? 1 : Number(opacity);
            //                     const { transform } = canvas1.style;
            //                     const matrix = transform
            //                         .match(/^matrix\(([^(]*)\)$/)[1]
            //                         .split(",")
            //                         .map(Number);
            //                     CanvasRenderingContext2D.prototype.setTransform.apply(
            //                         mapContext,
            //                         matrix
            //                     );
            //                     mapContext.imageSmoothingEnabled = true;
            //                     mapContext.imageSmoothingQuality = "high";
            //                     mapContext.drawImage(canvas1, 0, 0);
            //                 }
            //             }
            //         }
            //     );
            //     setMyCanvas(mapCanvas);
            //     // Reset original map size
            //     map.getTargetElement().style.width = "755px";
            //     map.getTargetElement().style.height = "552px";
            //     map.updateSize();
            //     map.getView().setResolution(viewResolution);
            //     document.body.style.cursor = "auto";
            // });+
            const format: string = formRef.current?.getFieldValue("pageSize");
            const scale = formRef.current?.getFieldValue("scale");
            const resolution = formRef.current?.getFieldValue("resolution");
            // const zoomValue = formRef.current?.getFieldValue("zoom");
            // const mapTitle: string = formRef.current?.getFieldValue("title")
            //    ? formRef.current?.getFieldValue("title")
            //    : `${zoomValue} ${format}  ${resolution}dpi ${scale}`;

            const dim = mapDims.get(format);

            // const orientation = formRef.current?.getFieldValue("orientation");

            const width = dim ? Math.round((dim[0] * resolution) / 25.4) : 1;
            const height = dim ? Math.round((dim[1] * resolution) / 25.4) : 1;

            const viewResolution = map?.getView().getResolution();
            const center = map?.getView().getCenter();
            let scaleResolution = 0;

            if (center && scale) {
                scaleResolution =
                    scale /
                    getPointResolution(
                        map?.getView().getProjection(),
                        resolution / 25.4,
                        center
                    );
            }

            const exportOptions = {
                useCORS: true,
                scrollX: -window.scrollX,
                scrollY: -window.scrollY,
                windowWidth: document.documentElement.offsetWidth,
                windowHeight: document.documentElement.offsetHeight,
                width,
                height,
            };
            // const scaleLine = new ScaleLine({
            //     units: "metric",
            //     bar: true,
            //     text: true,
            //     minWidth: 125,
            //     dpi: resolution,
            // });
            // map.addControl(scaleLine);
            if (map) {
                // scaleLine.setDpi(resolution);
                map.getTargetElement().style.width = `${width}px`;
                map.getTargetElement().style.height = `${height}px`;
                map.updateSize();
                map.getView().setResolution(scaleResolution);
            }

            map?.once("rendercomplete", function () {
                html2canvas(map.getViewport(), exportOptions).then(function (
                    canvas
                ) {
                    // const pdf = new JsPDF(orientation, "mm", format);
                    // switch (format) {
                    //     case "a0":
                    //         console.log(format);
                    //         printA0(pdf, canvas, mapTitle, colors, titles);
                    //         break;

                    //     default:
                    //         //
                    //         break;
                    // }
                    // pdf.save(
                    //     `map-${new Date(Date.now()).toLocaleDateString()}.pdf`
                    // );
                    // Reset original map size

                    map.getTargetElement().style.width = "755px";
                    map.getTargetElement().style.height = "552px";
                    map.updateSize();

                    map.getView().setResolution(viewResolution);
                    document.body.style.cursor = "auto";

                    setMyCanvas(canvas);
                });
            });
        });
        // setTime(new Date(Date.now()).toLocaleTimeString("pt-BR"));

        // const format: string = formRef.current?.getFieldValue("pageSize");
        // const resolution = formRef.current?.getFieldValue("resolution");
        // const zoomValue = formRef.current?.getFieldValue("zoom");
        // const mapTitle: string = formRef.current?.getFieldValue("title")
        //     ? formRef.current?.getFieldValue("title")
        //     : `${zoomValue} ${format}  ${resolution}dpi`;

        // const dim = mapDims.get(format);

        // const orientation = formRef.current?.getFieldValue("orientation");

        // const width = dim ? Math.round((dim[0] * resolution) / 25.4) : 1;
        // const height = dim ? Math.round((dim[1] * resolution) / 25.4) : 1;

        // const viewResolution = map?.getView().getResolution();
    }, [map]);

    const handleSubmit = () => {
        const orientation = formRef.current?.getFieldValue("orientation");
        const zoomValue = formRef.current?.getFieldValue("zoom");
        if (map) {
            if (orientation === "l") {
                map.getTargetElement().style.width = "755px";
                map.getTargetElement().style.height = "552px";
                map.updateSize();
            } else {
                map.getTargetElement().style.width = "552px";
                map.getTargetElement().style.height = "755px";
                map.updateSize();
            }
            map.getView().setZoom(zoomValue);
        }
    };
    useEffect(() => {
        if (map) {
            map.on("moveend", function handleZ() {
                setZoom(map.getView().getZoom());
            });
        }
    }, [map]);

    useEffect(() => {
        if (mycanvas) {
            const format: string = formRef.current?.getFieldValue("pageSize");
            const resolution = formRef.current?.getFieldValue("resolution");
            const scale = formRef.current?.getFieldValue("scale");
            // const zoomValue = formRef.current?.getFieldValue("zoom");
            const mapTitle: string = formRef.current?.getFieldValue("title")
                ? formRef.current?.getFieldValue("title")
                : ` ${format}  ${resolution}dpi ${scale}`;
            // : `${zoomValue} ${format}  ${resolution}dpi ${scale}`;

            const orientation = formRef.current?.getFieldValue("orientation");

            const pdf = new JsPDF(orientation, "mm", format);
            switch (format) {
                case "a0":
                    printA0(pdf, mycanvas, mapTitle, colors, titles);
                    break;
                case "a4":
                    printA4(pdf, mycanvas, mapTitle, colors, titles);
                    break;

                // case "a1":
                //     break;
                // case "a2":
                //     break;
                // case "a3":
                //     break;
                // case "a5":
                //     break;

                default:
                    //
                    break;
            }
        }
    }, [mycanvas]);

    // useEffect(() => {
    //     if (mycanvas) {
    //         console.log(colors);
    //         console.log(titles);
    //         console.log("mycanvas");
    //     }
    // }, [mycanvas]);

    useEffect(() => {
        createLegend();
        createControls();
    }, [map]);

    return (
        <>
            <div>
                <p style={{ fontStyle: "italic" }}>
                    * O zoom aplicado no mapa, deve ser informado na escala;
                </p>
                {/* <p style={{ fontStyle: "italic" }}>
                    * Ainda é necessário trabalhar na estrutura das legendas;
                </p> */}
                {/* <p style={{ fontStyle: "italic" }}>
                    * Ainda é necessário trabalhar na relação entre o zoom e a
                    escala;
                </p>
                <p style={{ fontStyle: "italic" }}>
                    * Para casos de muitas legendas, pode-se criar um
                    selecionador de legendas;
                </p> */}
            </div>
            <Form
                ref={formRef}
                className="py-6 grid grid-cols-4 gap-2"
                onSubmit={handleSubmit}
            >
                <div className="col-span-2">
                    <Input name="title" label="Título do Mapa" />
                </div>
                {/* <Select
                    name="zoom"
                    defaultValue={{
                        value: "18",
                        label: "18",
                    }}
                    // initValue={{
                    //     value: "18",
                    //     label: "18",
                    // }}
                    options={[
                        {
                            label: "14",
                            value: "14",
                        },
                        {
                            label: "17",
                            value: "17",
                        },
                        {
                            label: "18",
                            value: "18",
                        },
                        {
                            label: "19",
                            value: "19",
                        },
                        {
                            label: "20",
                            value: "20",
                        },
                    ]}
                    label="Zoom"
                    width="365px"
                /> */}
                <Select
                    name="orientation"
                    defaultValue={{
                        value: "l",
                        label: "Paisagem",
                    }}
                    // initValue={{
                    //     value: "l",
                    //     label: "Paisagem",
                    // }}
                    options={[
                        {
                            label: "Paisagem",
                            value: "l",
                        },
                        // {
                        //     label: "Retrato",
                        //     value: "p",
                        // },
                    ]}
                    label="Orientação"
                    width="365px"
                />
                <Select
                    name="pageSize"
                    defaultValue={{
                        value: "a4",
                        label: "a4",
                    }}
                    // initValue={{
                    //     value: "a4",
                    //     label: "a4",
                    // }}
                    options={[
                        {
                            label: "a0 lento",
                            value: "a0",
                        },
                        // {
                        //     label: "a1",
                        //     value: "a1",
                        // },
                        // {
                        //     label: "a2",
                        //     value: "a2",
                        // },
                        // {
                        //     label: "a3",
                        //     value: "a3",
                        // },
                        {
                            label: "a4",
                            value: "a4",
                        },
                        // {
                        //     label: "a5",
                        //     value: "a5",
                        // },
                    ]}
                    label="Página"
                    width="65px"
                />
                <Select
                    name="resolution"
                    defaultValue={{
                        label: "96 dpi",
                        value: "96",
                    }}
                    // initValue={{
                    //     label: "30 dpi",
                    //     value: "30",
                    // }}
                    options={[
                        // {
                        //     label: "20 dpi",
                        //     value: "20",
                        // },
                        // {
                        //     label: "30 dpi",
                        //     value: "30",
                        // },
                        {
                            label: "50 dpi",
                            value: "50",
                        },
                        {
                            label: "72 dpi",
                            value: "72",
                        },
                        {
                            label: "96 dpi",
                            value: "96",
                        },
                        {
                            label: "100 dpi",
                            value: "100",
                        },
                        {
                            label: "150 dpi",
                            value: "150",
                        },
                        {
                            label: "200 dpi",
                            value: "200",
                        },
                        // {
                        //     label: "300 dpi",
                        //     value: "300",
                        // },
                        // {
                        //     label: "350 dpi",
                        //     value: "350",
                        // },
                        // {
                        //     label: "400 dpi",
                        //     value: "400",
                        // },
                        // {
                        //     label: "450 dpi",
                        //     value: "450",
                        // },
                        // {
                        //     label: "495 dpi",
                        //     value: "495",
                        // },
                    ]}
                    label="Resolução/Qualidade"
                    width="365px"
                />
                <Select
                    name="scale"
                    defaultValue={{
                        label: "1:1000",
                        value: "1.0",
                    }}
                    options={[
                        // {
                        //     label: "1:50000",
                        //     value: "50.0",
                        // },
                        // {
                        //     label: "1:25000",
                        //     value: "25.0",
                        // },
                        {
                            label: "1:10000",
                            value: "10.0",
                        },
                        {
                            label: "1:7000",
                            value: "7.0",
                        },
                        {
                            label: "1:3000",
                            value: "3.0",
                        },
                        {
                            label: "1:2500",
                            value: "2.5",
                        },
                        {
                            label: "1:1000",
                            value: "1.0",
                        },
                        {
                            label: "1:500",
                            value: "0.5",
                        },
                    ]}
                    label="Escala"
                    width="565px"
                />
                {/* <div>
                    <Button
                        type="submit"
                        className="w-15"
                        // description="IMPRIMIR MAPA"
                    >
                        <p>APLICAR ZOOM</p>
                    </Button>
                </div> */}
                <div>
                    <Button
                        onClick={printMap}
                        type="button"
                        className="w-15"
                        // description="IMPRIMIR MAPA"
                    >
                        <img
                            src={PrintMapIcon}
                            className="h-6 w-6 md:h-7 md:w-7"
                            alt="IMPRIMIR MAPA"
                        />
                    </Button>
                </div>
                {/* <div>
                    <Button
                        onClick={createLegend}
                        type="button"
                        className="w-15"
                        // description="IMPRIMIR MAPA"
                    >
                        <img
                            src={InfoIcon}
                            className="h-6 w-6 md:h-7 md:w-7"
                            alt="InfoIcon"
                        />
                    </Button>
                </div> */}
            </Form>

            {zoom && <div>Zoom atual: {zoom}</div>}

            {/* {time && <div>Hora de início: {time}</div>}
            {timeEnd && <div>Hora de término: {timeEnd}</div>}
            {clique && <div>Clique: {clique}</div>}
            {colors && (
                <div>
                    colors:
                    {colors.map((e) => (
                        <div>{e}</div>
                    ))}
                </div>
            )}
            {titles && (
                <div>
                    titles:
                    {titles.map((e) => (
                        <div>{e}</div>
                    ))}
                </div>
            )} */}
        </>
    );
};
