import { useContext } from "react";
import { OlPdfContext, OlPdfContextData } from "../contexts/pdf-map.context";

export function usePdfMap(): OlPdfContextData {
    const context = useContext(OlPdfContext);

    if (!context) {
        throw new Error("usePdfMap must be used within an OlMapProvider");
    }

    return context;
}
