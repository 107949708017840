import React, { useEffect, useCallback } from "react";

import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";

import { View } from "ol";

import OlMap from "ol/Map";
import { WMTS } from "ol/source";
import TilegridWMTS from "ol/tilegrid/WMTS";
import environments from "../../../../environments";

import {
    findLayerByTitle,
    googleMapsLayer,
    googleMapsLayerVisible,
    openStreetMapLayer,
    transformCoordinates,
    wmtsSettings,
} from "../../../../shared/utils/ol.utils";

import { useAuth } from "../../../../shared/hooks/useAuth";
import { StoragesService } from "../../../../shared/storages/storages.service";
import { storageConstants } from "../../../../shared/utils/storage.utils";
import { usePdfMap } from "../../../../shared/hooks/usePdfMap";

import { LayersApiService } from "../../../../shared/services/api/layers-api.service";
import { PrintMapScreen } from "../exportMap/print-map-screen";

interface OLMapProps {
    center?: google.maps.LatLng | google.maps.LatLngLiteral | undefined;
    zoom: number;
    willRender?: boolean;
    children?: React.ReactNode;
}

const {
    geoserver: { url },
} = environments;

let hasBeenRendered = 0;

export const MapToExport: React.FC<OLMapProps> = ({
    center,
    zoom,
    willRender,
    children,
}) => {
    const { user, signed } = useAuth();
    const { map, setMap } = usePdfMap();

    if (willRender) {
        hasBeenRendered = 0;
    }
    const loadLayers = useCallback(async () => {
        const layersReponse = await LayersApiService.getLayers(
            "orderBy=ASC:cam_ordem&"
        );

        layersReponse.data.forEach(({ cam_nome_geoserver, cam_cache }) => {
            // caso o usuário esteja logado só alterar a visibilidade das camadas habilitadas na tela de entrada
            const layer = findLayerByTitle(
                map || ({} as OlMap),
                cam_nome_geoserver
            );

            const savedLayers = StoragesService.localStorage.getValue(
                storageConstants.ENABLED_LAYERS(user?.id)
            );

            const isLayerVisible = savedLayers
                ? (JSON.parse(savedLayers).includes(
                      cam_nome_geoserver
                  ) as boolean)
                : false;

            if (layer) return layer?.setVisible(signed ? isLayerVisible : true);

            // caso a camada tenha a opção de cache gerar wmts
            const source = cam_cache
                ? new WMTS({
                      url: `${url}/gwc/service/wmts`,
                      layer: cam_nome_geoserver,
                      matrixSet: "EPSG:4326",
                      format: "image/jpeg",
                      tileGrid: new TilegridWMTS({
                          tileSize: [256, 256],
                          extent: [-180.0, -90.0, 180.0, 90.0],
                          origin: [-180.0, 90.0],
                          resolutions: wmtsSettings.resolutions,
                          matrixIds: wmtsSettings.matrixIds,
                      }),
                      crossOrigin: "Anonymous",
                      projection: "EPSG:4326",
                      style: "",
                  })
                : new TileWMS({
                      url: `${url}/wms`,
                      params: { LAYERS: cam_nome_geoserver },
                      serverType: "geoserver",
                      crossOrigin: "Anonymous",
                      hidpi: false,
                      transition: 0,
                  });

            return map?.addLayer(
                new TileLayer({
                    properties: {
                        title: cam_nome_geoserver,
                    },
                    source,
                    visible: signed ? isLayerVisible : true,
                })
            );
        });
    }, [map, signed, user]);

    // map.setTarget(mapRef.current);
    // map.setView(
    //     new View({
    //         center: transformCoordinates([
    //             -5565593.9570694715, -2928746.061458326,
    //         ]),
    //         zoom: 15.741743598398308,
    //     })
    // );

    useEffect(() => {
        if (map) {
            loadLayers();
        }
    }, [loadLayers, map]);

    useEffect(() => {
        if (hasBeenRendered < 1) {
            const centered: Array<number> = [
                center?.lng as number,
                center?.lat as number,
            ];

            setMap(
                new OlMap({
                    controls: [],
                    layers: [
                        // openStreetMapLayer(),
                        googleMapsLayerVisible(),
                    ],
                    target: "map-export",
                    view: new View({
                        // center: transformCoordinates(
                        //     environments.webgeo.map.center
                        // ),
                        center: transformCoordinates(centered),
                        zoom,
                        // zoom: environments.webgeo.map.zoom,
                        // center: [-5565593.9570694715, -2928746.061458326],
                        // zoom: 15.741743598398308,
                    }),
                })
            );
            hasBeenRendered += 1;
        }
    }, [center?.lat, center?.lng, setMap, zoom]);

    return (
        <>
            <PrintMapScreen />

            <div
                style={{
                    position: "relative",
                    width: "755px",
                    height: "552px",
                    border: "1px solid black",
                    marginLeft: "5%",
                    marginTop: "1%",
                }}
                id="map-export"
            >
                {children}
            </div>
        </>
    );
};
