export enum ClientsEnum {
    PALMEIRA = "palmeira",
    CASTRO = "castro",
    ORTIGUEIRA = "ortigueira",
    IMBAU = "imbau",
    WENCESLAU_BRAZ = "wb",
    PIRAI = "pirai",
    JAGUARIAIVA = "jgv",
    BOMSUCESSO = "bomsucesso",
}
